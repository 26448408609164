<template>
  <div>
    <wd-navbar class="tarbar">
      <div slot="left" class="middle" @click="$goBack">
        <wd-icon name="arrow-left" />
      </div>
      <div>
        <span>{{equipInfo.acctName || '--'}}</span>
      </div>
    </wd-navbar>
    <div>
      <div>
<!--        <span style="margin-left: 15px; font-size: 14px; padding: 10px 0; display: block; color: #fa4350">缴费对象：{{equipInfo.acctName || '&#45;&#45;'}}</span>-->
        <wd-datetime-picker type="date" v-model="searchObject.startDate" :default-value="searchObject.startDate" label="开始时间"></wd-datetime-picker>
        <wd-datetime-picker type="date" v-model="searchObject.endDate" :default-value="searchObject.endDate" label="结束时间"></wd-datetime-picker>
      </div>
      <wd-cell title="合计充值">
        <font style="font-size: 18px; color: #fa4350">{{total || '0'}}元</font>
      </wd-cell>
      <div style="display: flex; align-items: center; justify-content: center">
        <wd-button class="button" @click="handleSearch">
          <img src="../../assets/img/search.svg" alt="" style="width: 18px; margin-right: 5px"> 搜 索
        </wd-button>
      </div>
    </div>
    <wd-cell-group border v-if="dataList.length > 0">
      <wd-cell class="labelWrap" v-for="(item, index) in dataList" :key="index">
        <div slot="title" style="color: rgba(0,0,0,.45)">
          {{item.createTime || '--'}}
        </div>
        <div slot="label" style="display: flex; align-items: center; font-size: 14px">
<!--          101电表 <img src="../../assets/img/ele.svg" style="width: 20px; margin-left: 10px" />-->
          缴费方式：{{item.channelid || '--'}}
        </div>
        <div style="color: #fa4350">
          {{item.amount || '0'}}元
        </div>
      </wd-cell>
      <wd-infinite-load ref="loadmore" @loadmore="loadmore" :loading="loading" v-if="showLoading"></wd-infinite-load>
    </wd-cell-group>
    <div style="background-color: #fff; margin-top: 15px" v-else>
      <wd-status-tip type="content" tip="暂无内容"/>
    </div>
  </div>
</template>

<script>
    export default {
        data(){
            return {
                total: 0,
                loadingData: true,
                showLoading: false,
                count: 10,
                loading: false,
                searchObject: {
                    showDate: false,
                    startDate: null,
                    endDate: null,
                },
                pageConfig: {
                    pageNum: 1,
                    pageSize: 10
                },
                equipInfo: {},
                dataList: []
            }
        },
        created() {
            this.$set(this.searchObject, "startDate", new Date(this.$moment().subtract(3, "days").format("YYYY-MM-DD")))
            this.$set(this.searchObject, "endDate", new Date(this.$moment().format("YYYY-MM-DD")))
            this.equipInfo = this.$route.params
            if( !this.equipInfo.acctId ) {
                this.$router.push('/home')
            } else {
                this.getList()
            }
        },
        mounted() {

        },
        methods: {
            handleSearch(){
                this.showLoading = false
                this.dataList = []
                this.pageConfig.pageNum = 1
                this.getList()
            },
            loadmore(){
                if( this.loadingData ) {
                    this.loadingData = false
                    setTimeout(()=>{
                        this.getList()
                    }, 1000)
                }
            },
            getList(){
                let startDate = this.$moment(this.searchObject.startDate).format("YYYY-MM-DD") + " 00:00:00"
                let endDate   = this.$moment(this.searchObject.endDate).format("YYYY-MM-DD") + " 23:59:59"
                let params    = {
                    acctId: this.equipInfo.acctId,
                    startDate: startDate,
                    endDate: endDate,
                    pageNum: this.pageConfig.pageNum,
                    pageSize: this.pageConfig.pageSize
                }
                this.showLoading = false
                this.$http.get("/external/recharge/list", { params: params }).then(res=>{
                  if( res.code == 200 ) {
                      this.total = res.msg
                      this.showLoading = true
                      this.loadingData = true
                      this.dataList = this.dataList.concat(res.rows);
                      if( this.dataList.length == res.total ) {
                          this.showLoading = false
                      }
                  }
                })
            },
        }
    }
</script>

<style lang="less" scoped>
  .button{
    /deep/ .wd-button__txt{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .labelWrap{
    /deep/ .wd-cell__wrapper{
      display: flex;
      align-items: center;
    }
  }
  .tarbar{
    /deep/ .wd-navbar__title{
      flex: 5;
      white-space: nowrap;
      overflow: visible;
    }
  }
</style>
